import { RiFacebookCircleLine } from "react-icons/ri";
import { RiTwitterLine } from "react-icons/ri";
import PageInto from "../components/PageIntro";
import teamPNG from "../assets/icons/team.png";
import { teamInfo } from "../static/team";
import { useInView } from "framer-motion";
import { useRef } from "react";


export default function Team() {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  return (
    <>
      <PageInto pageName={"Our Team"} />
      <img src={teamPNG} className="w-[60px] flex mx-auto mt-8" />
      <span className='flex justify-center text-black50 my-4'>Our Specialize Team</span>
      <h2 className="font-bold text-black md:text-h3 phone:text-h5 text-center max-w-[600px] mx-auto mt-4 px-2 uppercase">
        We Are A Creative Team For Your Dream Project
      </h2>
      <div className="max-w-[1280px] mx-auto mt-16 mb-36 flex flex-wrap justify-center overflow-hidden" ref={ref}>
        {teamInfo.map((member, index) => (
          <div
            style={{
              transform: isInView ? "none" : `translateY(100px)`,
              opacity: isInView ? 1 : 0,
              transition: "all 0.5s cubic-bezier(0.17, 0.55, 0.55, 1) 0.3s",
              transitionDelay: `${index / 2}s`
            }}
            className="w-[360px] h-[400px] relative m-8 overflow-hidden">
            {/* <span className="bg-secondary w-[80px] h-[6px] absolute top-0 left-0"></span>
            <span className="bg-secondary w-[6px] h-[80px] absolute top-0 left-0"></span> */}
            <img className="w-full" src={member.profile} />
            <div className="flex bg-white absolute bottom-0 p-4 w-full border-b-2">
              <div className="flex flex-col p-4 pl-1">
                <h2 className="text-xl font-bold text-black">{member.name}</h2>
                <span className="text-black50 text-sm">{member.profession}</span>
              </div>
              <div className="flex items-center absolute right-0 p-4 pr-0">
                <span className="bg-black p-2 rounded-full">
                  <RiFacebookCircleLine color="white" size={25} />
                </span>
                <span className="bg-black p-2 rounded-full sm:mx-4 phone:mx-2">
                  <RiTwitterLine color="white" size={24} />
                </span>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}
