import Heading from "../../components/Heading";
import choose from "../../assets/choose-2.jpg";
import { FaUsers } from "react-icons/fa";
import Saturate from "../../components/Saturate";
import { FaAward } from "react-icons/fa";
import useDevice from "../../useDevice";
import { SiAwsorganizations } from "react-icons/si";
import { BiSolidShoppingBags } from "react-icons/bi";


const ChooseUs = () => {
  const { isBigMobile } = useDevice();
  return (
    <div
      className={`lg:py-28 py-20 lg:pl-28 ${isBigMobile ? "px-0" : "px-4"
        } max-w-[4000px] px-auto`}
    >
      <div className="grid lg:grid-cols-11 grid-cols-1 md:max-w-[700px] mx-auto lg:max-w-full">
        <div
          className={`lg:col-span-3 lg:justify-self-end lg:place-self-end lg:-mr-64 ${isBigMobile ? "px-4" : ""
            }`}
        >
          <div className="relative md:max-w-[800px] md:w-[600px]">
            <img src={'https://images.unsplash.com/photo-1517245386807-bb43f82c33c4?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTh8fHNvZnR3YXJlJTIwYWdlbmN5fGVufDB8fDB8fHww'} alt="dialogmakers" 
            className="" />
            {/* <Saturate /> */}
            <div
              className="absolute top-0 left-0 right-0 bottom-0 back-color opacity-90"
              style={{
                "clip-path": "polygon(0 0, 45% 70%, 95% 100%, 0 100%)",
              }}
            >
              <div
                className={`absolute md:bottom-12 bottom-2 md:text-2xl font-semibold w-[40%] pl-4 ${isBigMobile ? "bottom-12 text-2xl" : ""
                  }`}
              >
                <span className="md:text-5xl text-3xl">
                  <FaAward color="white" />
                </span>
                <div className="md:mt-6 mt-2 text-white">
                  <span>AWARD WINNING TOP LEVEL WEB DEVLOPMENT COMPANY</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`lg:col-span-8 justify-self-end content-center lg:bg-gray-200 lg:pl-72 lg:pr-4 lg:pb-12 w-full lg:h-[610px] xl:h-[590px] xl:py-12 ${isBigMobile ? "px-16" : ""
            }`}
        >
          <div className="mt-16 xl:max-w-[80%] lg:max-w-[100%]">
            <div>
              <Heading
                title="Agency benefits"
                heading="WHY YOU SHOULD CHOOSE DIALOGMAKERS-INTL."
                w="w-[90%]"
              />
            </div>
            <div className="mt-8">
              <div className="flex gap-8 items-center">
                <div className="back-color p-8 text-4xl">
                  <SiAwsorganizations color="white" />
                </div>
                <div>
                  <h3 className="text-xl font-semibold">THE GROWING FIRM</h3>
                  <p className="text-black75 text-base">
                    The Growing Firm drives sustainable success by empowering businesses with innovative, tailored solutions for global growth.
                  </p>
                </div>
              </div>
              <div className="flex mt-8 gap-8 items-center">
                <div className="back-color p-8 text-4xl">
                  <BiSolidShoppingBags color="white" />
                </div>
                <div>
                  <h3 className="text-xl font-semibold">GOOD WORKING EXPERIENCE</h3>
                  <p className="text-black75 text-base">
                    Good Working Experience enhances success by fostering a collaborative, productive environment where innovation and excellence thrive.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChooseUs;
