import NavBar from "../NavBar";
import Button from "../../components/Button";
import "swiper/css";
import useDevice from "../../useDevice";
import { useInView } from "framer-motion";
import { useRef } from "react";



const Landing = ({ cursorRef }) => {

  const { isBigMobile } = useDevice();
  return (
    <div className="relative w-full md:h-screen h-[80vh]">
      {/* <div className="cursor-tail hidden lg:block" ref={cursorRef}></div> */}
      <div className="bg-[rgba(0,0,0,0.9)] absolute top-0 left-0 bottom-0 right-0">
        <div className="landing opacity-5"></div>
      </div>
      <div className="z-10 text-gray-200 relative">
        <NavBar />
      </div>
      <div
        className={`h-full absolute top-0 left-0 right-0 bottom-0 px-0 ${isBigMobile ? "pl-16" : ""
          }`}
      >
        <SwiperItem />
        {/* <Swiper
          className="h-[inherit]"
          // modules={[Autoplay]}
          autoplay={{ delay: 2500, disableOnInteraction: false }}
          speed={2000}
          loop={true}
        >
          <SwiperSlide>
            <SwiperItem />
          </SwiperSlide>
          <SwiperSlide>
            <SwiperItem />
          </SwiperSlide>
        </Swiper> */}
      </div>
    </div>
  );
};

export const SwiperItem = () => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  const {
    isBigMobile,
    isBigTablet,
    isSmallLaptop,
    isBigLaptop,
    isMediumLaptop,
  } = useDevice();
  return (
    <>
      <div
        className={` mx-auto ${isBigTablet
          ? "max-w-[900px]"
          : "xl:max-w-[1200px] md:max-w-[700px] lg:max-w-[950px]"
          }`}
      >
        <div className="grid grid-cols-2" ref={ref}>
          <div
            style={{
              transform: isInView ? "none" : `translateY(100px)`,
              opacity: isInView ? 1 : 0,
              transition: "all 0.5s cubic-bezier(0.17, 0.55, 0.55, 1) 0.3s",
            }}
            className="absolute top-[30%] left-[10%]">
            <div className="text-gray-200">
              <div className="flex">
                <div className="relative">
                  <h4 className="text-color md:text-xl phone:text-xs tracking-widest uppercase">
                    WE ARE THE LEADER IN
                  </h4>
                  <h1
                    className="text-white xl:text-h1  phone:text-h2 uppercase md:max-w-[600px] phone:max-w-[300px] leading-tight"
                  >
                    Creative & Innovative Digital Solution
                  </h1>
                  <button
                    onClick={() => window.scrollTo(0, document.body.scrollHeight)}
                    className="font-bold bg-primary p-4 px-8 rounded-full text-white text-xl mt-6">
                    Contact us
                  </button>
                  <div
                    className={`absolute bottom-0 hidden  xl:block ${isBigTablet ? "left-[30%]" : "xl:left-[25%] md:left-[40%]"
                      } ${isSmallLaptop ? "hidden" : "block"}`}
                  >
                    {/* <img src={shape4} alt="shape4" /> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className={`absolute right-0 top-0 lg:block ${isBigTablet ? "block" : "hidden"
              }`}
          >
            <div className="relative">
              <div
                className={`hero-area-img h-screen clip-path-custom relative`}
              >
                <img
                  src={'https://images.pexels.com/photos/3194519/pexels-photo-3194519.jpeg?auto=compress&cs=tinysrgb&w=800'}
                  alt="dialogmakers-image"
                  className="h-full w-full object-cover opacity-50"
                />
                {/* <Saturate /> */}
              </div>
              {/* <div className={`lg:block ${isBigTablet ? "block" : "hidden"}`}>
                <div
                  className={`absolute top-0  ${isSmallLaptop
                      ? "left-[40%]"
                      : isMediumLaptop
                        ? "left-[50%]"
                        : "xl:left-[30%] md:left-[60%] lg:left-[55%]"
                    }`}
                >
                  <img src={shape1} alt="shape1" />
                </div>
                <div
                  className={`absolute bottom-0 ${isBigTablet && "hidden"} ${isSmallLaptop
                    ? "left-[30%]"
                    : isMediumLaptop
                      ? "left-[20%]"
                      : "xl:left-[0%] lg:left-[30%]"
                    }`}
                >
                  <img src={shape2} alt="shape2" />
                </div>
                <div
                  className={`absolute bottom-0   ${isBigTablet && "hidden"} ${isSmallLaptop
                    ? "left-[25%]"
                    : isMediumLaptop
                      ? "left-[15%]"
                      : "xl:-left-[5%] lg:left-[25%]"
                    }`}
                >
                  <img src={shape3} alt="shape3" />
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Landing;
