export const ClientReviews = [
    {
        img: 'https://dialogmakers-international.com/img/team-2.jpg',
        name: 'May',
        review: 'quick response, nice job, a great partner to work with!'

    },
    {
        img: 'https://dialogmakers-international.com/img/testimonial-3.jpg',
        name: 'Rodriguez',
        review: 'Their web development expertise has transformed our website into a sleek and user-friendly platform, attracting more customers than ever before. Thanks, Dialogmakers'

    },
    {
        img: 'https://dialogmakers-international.com/img/testimonial-2.png',
        name: 'Reynold',
        review: 'Excellent service provided by Dialogmakers! Their team demonstrated high professionalism, crafting a website that perfectly aligned with our vision. The seamless communication, timely delivery, and attention to detail truly set them apart. Highly recommended for anyone seeking a robust online presence.'

    }

]