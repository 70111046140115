import wwwPNG from '../assets/icons/www.png'
import PageInto from '../components/PageIntro';
import dollerTag from '../assets/icons/dollerTag.png'
import { useState, useRef } from 'react';
import { useInView } from "framer-motion";
import { SlCheck } from "react-icons/sl";
import { IoLanguage } from "react-icons/io5";
import {
    pricing_web_dev,
    pricing_app_dev,
    pricing_social_marketing,
    pricing_publishing,
    RaNexXus_publishing
} from '../static/pricing';
import '../CSS/pricing.css'

export default function Pricing() {
    const ref = useRef(null);
    const isInView = useInView(ref, { once: true });
    const [activeMenu, setActiveMenu] = useState(pricing_web_dev)
    const activeMenuOptStyle = 'm-2 text-white lg:text-lg sm:text-base phone:text-sm p-3 bg-primary font-bold mx-3 border-2 border-primary rounded border-b-0 relative cursor-pointer'
    const deactiveMenuOptStyle = 'm-2 lg:text-lg sm:text-base phone:text-sm p-3 mx-3 bg-white relative cursor-pointer'
    return (
        <>
            <PageInto pageName={'Pricing'} />
            <div className="flex flex-col items-center mt-16">
                <span>
                    <img src={dollerTag} className='w-[60px]' />
                </span>
                <span className='text-black50 my-4'>Our Project Pricing</span>
                <h2 className="font-bold text-black md:text-h3 phone:text-h5 text-center max-w-[900px] mx-auto px-2 mt-8 uppercase">
                    OUR PLANS ARE BASED ON PROJECT LENGTH & FEATURES.
                </h2>
                <div className='flex flex-wrap mt-16 border-primary w-full justify-center'>
                    <span
                        onClick={() => setActiveMenu(pricing_web_dev)}
                        className={activeMenu === pricing_web_dev ? activeMenuOptStyle : deactiveMenuOptStyle}>
                        WEB DESIGN & DEVELOPMENT
                    </span>
                    <span
                        onClick={() => setActiveMenu(pricing_app_dev)}
                        className={activeMenu === pricing_app_dev ? activeMenuOptStyle : deactiveMenuOptStyle}>
                        APP DEVELOPMENT
                    </span>
                    <span
                        onClick={() => setActiveMenu(pricing_social_marketing)}
                        className={activeMenu === pricing_social_marketing ? activeMenuOptStyle : deactiveMenuOptStyle}>
                        SOCIAL MARKETING
                    </span>
                    <span
                        onClick={() => setActiveMenu(pricing_publishing)}
                        className={activeMenu === pricing_publishing ? activeMenuOptStyle : deactiveMenuOptStyle}>
                        PUBLISHING
                    </span>
                    <span
                        onClick={() => setActiveMenu(RaNexXus_publishing)}
                        className={activeMenu === RaNexXus_publishing ? activeMenuOptStyle : deactiveMenuOptStyle}>
                        RaNexXus Publishing
                    </span>
                </div>
                {activeMenu !== RaNexXus_publishing &&
                    <div className='flex flex-wrap my-16 justify-center mx-auto bg-white' ref={ref}>
                        {activeMenu.map((val, index) => (
                            <div
                                style={{
                                    transform: isInView ? "none" : `translateY(100px)`,
                                    opacity: isInView ? 1 : 0,
                                    transition: "all 0.5s cubic-bezier(0.17, 0.55, 0.55, 1) 0.3s",
                                    transitionDelay: `${index / 2}s`
                                }}
                                className='pricing-container border-2 relative md:m-4 phone:my-4 cursor-pointer transition-all duration-300 bg-white'>
                                <div className='flex flex-col w-[360px] '>
                                    <span className='flex font-bold w-max px-4 justify-center p-2 bg-primary text center text-lg text-white rounded-full m-4 shadow-lg'>
                                        {val.plan}
                                    </span>
                                    <div className='card-head flex flex-col items-center my-4'>
                                        <span className='my-2'>
                                            <img src={wwwPNG} className='w-[40px]' />
                                        </span>
                                        <span className='text-[40px] font-bold'>${val.price}</span>
                                        <span className='planInfo text-sm text-center text-black75'>{val.planInfo}</span>
                                    </div>
                                    <span className='m-4 p-2 bg-primary w-max rounded-full px-4 shadow-lg text-white text-sm'>SERVICE INCLUDES</span>
                                    <div className='flex flex-col my-2 w-full m-2 mb-16'>
                                        {val.offers.map(offer => (
                                            <div className='flex items-start p-2'>
                                                <span className='mt-1'>
                                                    <SlCheck />
                                                </span>
                                                <span className='offer mx-2 text-base font-bold text-black'>{offer}</span>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <button
                                    className='pricing-container-btn bg-black text-white font-bold text-center w-full absolute bottom-0 p-2 text-lg '>
                                    SELECT THIS PLAN
                                </button>
                            </div>
                        ))}
                    </div>
                }
                {/* RaNexXus Publishing */}
                {activeMenu == RaNexXus_publishing &&
                    <div className='flex flex-col my-16 justify-center mx-auto bg-white'>
                        {activeMenu.map((val, index) => (
                            <div className='flex lg:w-[1000px] phone:w-[95%] phone:mx-auto my-8 phone:flex-col lg:flex-row'>
                                <img
                                    className='h-[400px] w-max phone:mx-auto'
                                    src={val.cover}
                                    alt=""
                                />
                                <div className='flex flex-col relative phone:items-center phone:text-center lg:items-start lg:text-left'>
                                    <h2 className='font-bold text-black lg:text-h4 phone:text-h6 px-4 mt-4'>
                                        {val.title}
                                    </h2>
                                    <span className='flex text-h6 text-black75 mt-2 mb-4 font-bold px-4'>
                                        {val.price}
                                    </span>
                                    <p className='text-black75 lg:w-[80%] px-4 text-ellipsis overflow-hidden line-clamp-4 '>
                                        {val.description}
                                    </p>
                                    <div className='flex mt-4 items-center px-4 phone:mb-16'>
                                        <IoLanguage size={20} color='#616B76' />
                                        <span className='mx-2 text-black font-bold text-xs'>{val.language}</span>
                                    </div>
                                    <a
                                        href={val.link}
                                        className='bg-black text-white font-bold text-center w-[-webkit-fill-available] absolute bottom-0 py-2 text-lg hover:bg-gradient-to-r from-cyan-500 to-blue-500 transition-all duration-500'>
                                        BUY ON AMAZON
                                    </a>
                                </div>
                            </div>
                        ))
                        }
                    </div>
                }
            </div>
        </>
    )
}