import Heading from "../../components/Heading";
import { portfolio } from "../../static-data";
import Saturate from "../../components/Saturate";
import useDevice from "../../useDevice";
import { ClientReviews } from "../../static/client_reviews";
import { useRef } from "react";
import { useInView } from "framer-motion";


const Portfolio = () => {
  const { isBigMobile } = useDevice();
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  return (
    <>
      <div className="pt-24 pb-12">
        <Heading
          title="TESTIMONIAL"
          heading="What Our Clients Say!"
          className="flex justify-center text-center"
          logoImg="middle"
        />
      </div>
      <div className="flex w-full justify-center flex-wrap gap-4" ref={ref}>
        {ClientReviews.map((val, index) => (
          <div
            style={{
              transform: isInView ? "none" : `translateY(100px)`,
              opacity: isInView ? 1 : 0,
              transition: "all 0.5s cubic-bezier(0.17, 0.55, 0.55, 1) 0.3s",
              transitionDelay: `${index/2}s`
            }}
            className="flex flex-col max-w-[300px] border p-4 rounded-md hover:border-primary">
            <div className="flex items-center">
              <img
                className="w-[60px] rounded-full border p-2"
                src={val.img}
                alt="" />
              <span
                className="font-bold text-black text-xl mx-4">
                {val.name}
              </span>
            </div>
            <span
              className="text-lg text-black75 m-2">
              {val.review}
            </span>
          </div>
        ))}
      </div>
    </>
  );
};

export default Portfolio;
