import Button from "../components/Button";
import { MdOutlineHeadsetMic } from "react-icons/md";

export default function ContactForm() {
  return (
    <div className="flex flex-col">
      <h2 className="flex items-center font-bold md:text-h3 phone:text-h5 text-black mt-8">
        GET IN TOUCH &nbsp;
        <MdOutlineHeadsetMic size={40} color="#2D3949" />
      </h2>
      <span className="text-black50 text-sm phone:text-xs mx-1">
        Provide your contact details, We will reach out to you soon.
      </span>
      <form className="lg:w-[800px] sm:w-[500px] phone:w-[95%]  mt-8">
        <div className="flex phone:flex-col lg:flex-row">
          <input
            className="bg-[#f2f2f6] p-4 lg:w-1/2 phone:w-full m-2"
            type="text"
            placeholder="Name"
          />
          <input
            className="bg-[#f2f2f6] p-4 lg:w-1/2 phone:w-full m-2"
            type="email"
            placeholder="Email"
          />
        </div>
        <div className="flex phone:flex-col lg:flex-row">
          <input
            className="bg-[#f2f2f6] p-4 lg:w-1/2 phone:w-full m-2"
            type="text"
            placeholder="Phone"
          />
          <input
            className="bg-[#f2f2f6] p-4 lg:w-1/2 phone:w-full m-2"
            type="email"
            placeholder="Organization/Service"
          />
        </div>
        <textarea
          className="bg-[#f2f2f6] p-4 w-[98%] m-2 resize-none h-[200px]"
          placeholder="Message"
        ></textarea>
        <Button title={"SEND A MESSAGE"} className="flex mt-4 mx-auto" />
      </form>
    </div>
  );
}
