import useDevice from "../useDevice";

const Wrapper = ({ children, className }) => {
  const { isBigMobile } = useDevice();
  return (
    <div
      className={`lg:py-28 py-20 px-4 ${className} ${
        isBigMobile ? "pl-16 pr-16" : ""
      }`}
    >
      <div className="xl:max-w-[1200px] md:max-w-[700px] lg:max-w-[950px] mx-auto">
        {children}
      </div>
    </div>
    // </div>
  );
};

export default Wrapper;
