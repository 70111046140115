export const portfolio = [
    {
        img: 'https://dialogmakers-international.com/img/Projects/TM.jpg',
        name: 'Mortgage Releive'
    },
    {
        img: 'https://dialogmakers-international.com/img/Projects/TMM.jpg',
        name: 'Taiwan Mia'
    },
    {
        img: 'https://dialogmakers-international.com/img/Projects/MP.jpg',
        name: 'MAZU Production'
    },
    {
        img: 'https://dialogmakers-international.com/img/Projects/FT.jpg',
        name: 'FlirtTool'
    },
    {
        img: 'https://dialogmakers-international.com/img/Projects/MC.jpg',
        name: 'MAZU Commnunites'
    },

]