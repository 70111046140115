const Button = ({ title, className, divClass }) => {
  return (
    <div className={divClass}>
      <button
        className={` bg-primary text-white px-12 py-4 text-black text-lg font-semibold uppercase ${className}`}
      >
        {title}
      </button>
    </div>
  );
};

export default Button;
