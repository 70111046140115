import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Contact from "../src/pages/Contact";
import Team from "./pages/Team";
import Pricing from "./pages/Pricing";
import AboutPage from "./pages/AboutPage";
import Portfolio from "./pages/Portfolio";
import Services from "./pages/Services";
import Footer from "./layouts/Footer";


const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/contact",
    element: <Contact />,
  },
  {
    path: "/services",
    element: <Services />,
  },
  {
    path: "/team",
    element: <Team />,
  },
  {
    path: "/pricing",
    element: <Pricing />,
  },
  {
    path: "/portfolio",
    element: <Portfolio />,
  },
  {
    path: "/about-us",
    element: <AboutPage />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
    <Footer />
  </React.StrictMode>
);
