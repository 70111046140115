import joe from '../assets/profiles/Joe.png'
import eric from '../assets/profiles/Eric.png'
import Sumit from '../assets/profiles/Sumit.png'
import nilsha from '../assets/profiles/Nilshan.png'
import nichole from '../assets/profiles/Nichole.png'
import isabelle from '../assets/profiles/Issabella.png'
import Bineeesh from '../assets/profiles/Bineeesh.png'
import ajayPhoto from '../assets/profiles/ajay.jpeg'
import sanketPhoto from '../assets/profiles/sanket.png'
import anthonyPhoto from '../assets/profiles/anthony.jpeg'



export const teamInfo = [
    {
        profile: joe,
        name: "Chao Ching HSU",
        profession: "Director & Founder",
        fb: "",
        twitter: "",
    },
    {
        profile: eric,
        name: "Eric Ronnau",
        profession: "CEO & Founder",
        fb: "",
        twitter: "",
    },
    {
        profile: Sumit,
        name: "Sumit Chauhan",
        profession: "Full Stack Developer",
        fb: "",
        twitter: "",
    },
    {
        profile: nilsha,
        name: "Nilshan Alagujara",
        profession: "Frontend Developer",
        fb: "",
        twitter: "",
    },
    {
        profile: nichole,
        name: "Russel Nicole Bureros",
        profession: "Senior Project Manager",
        fb: "",
        twitter: "",
    },
    {
        profile: isabelle,
        name: "Isabelle Ratajczak",
        profession: "Project Manager",
        fb: "",
        twitter: "",
    },
    {
        profile: Bineeesh,
        name: "Binesh",
        profession: "Server Administrator",
        fb: "",
        twitter: "",
    },
    {
        profile: ajayPhoto,
        name: "Ajay Tiwari",
        profession: "Full Stack Developer",
        fb: "",
        twitter: "",
    },
    {
        profile: anthonyPhoto,
        name: "Anthony",
        profession: "Web Developer",
        fb: "",
        twitter: "",
    },
    {
        profile: sanketPhoto,
        name: "Sanket Mhaiske",
        profession: "Web Developer",
        fb: "",
        twitter: "",
    },
];
