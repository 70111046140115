import { IoMailOutline } from "react-icons/io5";
import { CiLocationOn } from "react-icons/ci";
import { MdOutlineWifiCalling3 } from "react-icons/md";
import ContactForm from "../components/contactForm";
import PageInto from "../components/PageIntro";

export default function Contact() {
  return (
    <>
      <PageInto pageName={"Contact"} />
      {/* Google Map */}
      <div className="flex justify-center mx-auto mt-16 bg-[#ffffff] h-[550px] mb-8 max-w-[1200px] phone:w-[95%] border relative">
        <iframe
          className="w-full h-full mix-blend-luminosity h-[500px]"
          src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d3617.2942514480856!2d121.20068637525581!3d24.956101441424018!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1722229723612!5m2!1sen!2sin"
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
        <div className="flex xl:flex-row phone:flex-col justify-between xl:items-end w-[90%] mx-auto absolute top-[75%] max-w-[1024px]">
          <div className="flex flex-col bg-primary sm:p-12 phone:p-4 sm:w-[336px] phone:w-[280px] text-white">
            <span className="bg-secondary w-max h-max p-2 rounded-full mr-4">
              <MdOutlineWifiCalling3 color="white" size={22} />
            </span>
            <h2 className="font-semibold mt-4 sm:text-2xl phone:text-xl">
              DO YOU STILL HAVE QUESTIONS?
            </h2>
            <span className="font-semibold mt-2 mb-1">Call anytime</span>
            <span>+91 7878787878</span>
          </div>
          <div className="flex my-8 phone:flex-col md:flex-row">
            <div className="flex border-b py-4">
              <span className="bg-black w-max h-max p-2 rounded-full mr-4">
                <IoMailOutline color="white" size={22} />
              </span>
              <div className="flex flex-col">
                <span>WRITE EMAIL</span>
                <span className="font-bold text-xl">needhelp@company.com</span>
              </div>
            </div>
            <div className="flex border-b py-4 ml-8">
              <span className="bg-black w-max h-max p-2 rounded-full mr-4">
                <CiLocationOn color="white" size={22} />
              </span>
              <div className="flex flex-col">
                <span>VISIT OFFICE</span>
                <span className="font-bold text-xl">
                  24 Valentin, Street Road New York
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-center mx-auto xl:mt-56 phone:mt-80 max-w-[1200px] mb-16">
        <ContactForm />
      </div>
    </>
  );
}
