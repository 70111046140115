import book1 from '../assets/images/books/book1.png'
import book2 from '../assets/images/books/book2.png'
import book3 from '../assets/images/books/book3.png'
import book4 from '../assets/images/books/book4.png'
import book5 from '../assets/images/books/book5.png'
import book6 from '../assets/images/books/book6.png'
import book7 from '../assets/images/books/book7.png'
import book8 from '../assets/images/books/book8.png'
import book9 from '../assets/images/books/book9.png'
import book10 from '../assets/images/books/book10.png'



export const pricing_web_dev = [
    {
        plan: 'Basic',
        price: '375',
        planInfo: 'MAX 4 PAGES WEBSITE',
        offers: [
            'Responsive Design',
            'User-Friendly Navigation',
            'Custom Design and Branding',
        ],

    },
    {
        plan: 'Pro',
        price: '575',
        planInfo: 'MAX 8 PAGES WEBSITE',
        offers: [
            'Responsive Design',
            'User-Friendly Navigation',
            'SEO Optimization',
            'Performance and Speed',
            'Custom Design and Branding',
        ],

    },
    {
        plan: 'Custom',
        price: '975',
        planInfo: '8+ PAGES WEBSITE',
        offers: [
            'Responsive Design',
            'UX-Optimization',
            'User-Friendly Navigation',
            'SEO Optimization',
            'Performance and Speed',
            'Custom Design and Branding',
        ],

    },

]

export const pricing_app_dev = [
    {
        plan: 'Basic',
        price: '475',
        planInfo: 'For Small businesses',
        offers: [
            'iOS/Android',
            'Pre-built templates with minor customization',
            'Core features',
            'API Integration',
            'Support 1 Month',
            'Maintenance - additional Cost',
        ],
    },
    {
        plan: 'Pro',
        price: '675',
        planInfo: 'For Growing business',
        offers: [
            'iOS/Android',
            'Custom UI/UX',
            'Advanced features',
            'API Integration',
            'Support 3 Months',
            'Maintenance - 1 Years',
        ],
    },
    {
        plan: 'Custom',
        price: '1075',
        planInfo: 'For Growing business',
        offers: [
            'iOS/Android/webapp',
            'Fully Custom UI/UX',
            'Advanced features + Security',
            'Custom API Integration',
            'Dedicated Support',
            'Maintenance - 2 Years',
        ],
    },
]

export const pricing_social_marketing = [
    {
        plan: 'Basic',
        price: '575',
        planInfo: 'For Small businesses/startups',
        offers: [
            'Facebook/Insta',
            '5-8 posts per month with basic graphics',
            'Basic engagement',
            'Monthly performance report',
            'Ads: Optional, with a small budget',
            'Email support',
        ],
    },
    {
        plan: 'Pro',
        price: '775',
        planInfo: 'For Growing businesses/boost social media presence',
        offers: [
            'Facebook/Insta/Twitter/Linkedin',
            '12-15 posts per month with custom graphics',
            'Active engagement',
            'Bi-weekly performance reports with insights',
            'Managed ad campaigns with a moderate budget',
            'Email and chat support',
        ],
    },
    {
        plan: 'Custom',
        price: '1175',
        planInfo: 'For Enterprises or organizations',
        offers: [
            'Multiple platforms, including niche social networks',
            'Unlimited posts with custom graphics, videos, and animations',
            'Full-time engagement team',
            'Weekly performance reports with detailed analysis',
            'Tailored content strategy',
            'EComprehensive ad campaigns',
            'Dedicated account manager with 24/7 support',
        ],
    },
]

export const pricing_publishing = [
    {
        plan: 'Basic',
        price: '675',
        planInfo: 'Straightforward publishing needs',
        offers: [
            'Manuscript Review',
            'Standard formatting',
            'Pre-made cover template',
            'eBook distribution on major platforms (e.g., Amazon, Apple Books)',
            '1 ISBN provided',
            'Email support',
        ],
    },
    {
        plan: 'Pro',
        price: '875',
        planInfo: 'More polished and professional ',
        offers: [
            'Comprehensive editing',
            'Custom formatting ',
            'Custom-designed cover',
            'eBook and print distribution on multiple platforms',
            '2 ISBNs provided (eBook and print)',
            'Basic marketing package (Press release/Social media promotion)',
            'Email and chat support',
        ],
    },
    {
        plan: 'Custom',
        price: '1275',
        planInfo: 'Established authors, businesses, or organizations',
        offers: [
            'Full developmental editing, copyediting, and proofreading',
            'Premium custom formatting',
            'Fully custom cover design',
            'Global distribution for both eBook and print',
            'Multiple ISBNs for different formats and editions',
            'Comprehensive marketing package',
            'Dedicated project manager with 24/7 support',
        ],
    },

]

export const RaNexXus_publishing = [

    {
        cover: book1,
        price: '$3.99',
        link: 'https://www.amazon.com/dp/B0DBQ2WDYV',
        description: `Taiwan [UN] Limited is not just a survival story—it's a celebration of life, an exploration of what it means to start over, and a testament to the strength we all carry within us`,
        title: 'Taiwan [UN]Limited: My Life After an Aortic Dissection',
        language: 'English'
    },
    {
        cover: book2,
        price: '$4.99',
        link: 'https://www.amazon.com/dp/B0DD1JF2CM',
        description: 'Its a Journey through recovery and self-discovery following a life-altering medical crisis. "Growth and Change" delves into the profound impact of community support, emotional resilience, and self-care practices as Eric navigates the challenges and triumphs of healing and personal growth.',
        title: 'Taiwan [UN]Limited - My Life After The Aortic Dissection : Growth and Change',
        language: 'English'
    },
    {
        cover: book3,
        price: '$3.99',
        link: 'https://www.amazon.com/dp/B0DBVX9PR3',
        description: 'At the age of 40, just two years after surviving a major heart surgery for an aortic dissection, I was diagnosed with Type 2 diabetes. This book details my journey through the complexities of managing diabetes while navigating life as an expat in Taiwan.',
        title: 'Taiwan [UN]Limited: Diabetes at 40?: My experiences with diabetes after being diagnosed at the age of 40',
        language: 'English'
    },
    {
        cover: book4,
        price: '€3.99',
        link: ' https://www.amazon.de/dp/B0DBPN91JC',
        description: `Eine plötzliche, schwere Aortendissektion brachte mich an den Rand des Todes und führte mich auf eine Reise voller Herausforderungen, Kampfgeist und Selbstentdeckung. In diesem Buch teile ich meine Erlebnisse nach der Operation, von der physischen Genesung bis hin zu den emotionalen Hürden, die ich überwinden musste.`,
        title: 'Taiwan UNLimited: Mein Leben nach einer Aortendissektion (Taiwan [UN]Limited)',
        language: 'German'
    },
    {
        cover: book5,
        price: '€4.99',
        link: 'https://www.amazon.de/dp/B0DD1M9WZV',
        description: `"Wachstum und Wandel" ist eine emotionale Achterbahnfahrt, die zeigt, wie starke Gemeinschaften, emotionale Resilienz und Selbstfürsorge zu Heilung und persönlichem Wachstum führen können.`,
        title: 'Taiwan [UN]Limited - Mein Leben nach der Aortendissektion: Wachstum und Wandel',
        language: 'German'
    },
    {
        cover: book6,
        price: '$3.99',
        link: 'https://www.amazon.com/dp/B0DCDJ34TR',
        description: `When Alex is hit by a devastating loss, he embarks on a paranormal fantasy quest that takes him deep into the heart of Taiwan’s mythical landscapes. Along the way, he faces dark forces that threaten the balance between the world of the living and the dead. Guided by the whispers of his ancestors and driven by a need for answers, Alex must unlock hidden secrets, navigate mystical worlds, and overcome ancient trials in this unforgettable magical adventure.`,
        title: 'The Spirit Book - Alex Chronicles: The Spirit Bridge - Alex first Journey',
        language: 'English'
    },
    {
        cover: book7,
        price: '€3.99',
        link: 'https://www.amazon.de/dp/B0DCFR7FH2',
        description: `Als Alex durch einen tragischen Verlust in eine Welt voller Geheimnisse und magischer Kräfte geworfen wird, beginnt eine Reise, die ihn nicht nur durch das Land, sondern auch durch die tiefsten Winkel seiner Seele führt. Geführt von den flüsternden Stimmen seiner Ahnen und getrieben von einem unstillbaren Durst nach Antworten, muss Alex uralte Prüfungen bestehen, verborgene Tempel entdecken und die finsteren Mächte bekämpfen, die das fragile Gleichgewicht zwischen den Welten bedrohen.`,
        title: 'The Spirit Book - Alex Chronicles: Die Geisterbrücke - Alex erstes Abenteuer ',
        language: 'German'
    },
    {
        cover: book8,
        price: '€3.99',
        link: 'https://www.amazon.de/dp/B0DDQ6G2MV',
        description: `Kevin Kaiser dachte, er hätte alles im Griff – bis er sein Liebesleben in die Hände einer Dating-App namens MatchMeNow legte. Was als hoffnungsvoller Versuch begann, der Einsamkeit zu entkommen, entwickelt sich schnell zu einer Serie von absurden, skurrilen und einfach unvergesslichen Begegnungen.`,
        title: 'Arschvhaare Rauchen!: MatchMeNow - Die unzensierte Wahheit',
        language: 'German'
    },
    {
        cover: book9,
        price: '€3.99',
        link: 'https://www.amazon.de/dp/B0DDM88543',
        description: `Stellenanzeigen Kiffen" kämpft Kevin sich durch skurrile Stellenanzeigen, absurde Bewerbungsgespräche und noch verrücktere Arbeitsplätze. Ob als Verkäufer in einem unheimlichen Damenmodengeschäft, als Eventmanager bei einer totalen Katastrophe oder als undercover Chat-Moderator – Kevin trifft auf seltsame Vorgesetzte, absurde Arbeitskollegen und Jobangebote, die so bizarr sind, dass man sie nur bekifft ertragen kann. Dieser satirische Roman wirft einen humorvollen und provokanten Blick auf die Arbeitswelt und lässt kein Fettnäpfchen aus. Willkommen in der Realität – oder dem, was davon übrig ist.`,
        title: 'Stellenanzeigen Kiffen: Karriereplanung für Durchgeknallte (Arschhaare Rauchen 2)',
        language: 'German'
    },
    {
        cover: book10,
        price: '€3.99',
        link: 'https://www.amazon.de/dp/B0DDQ7CZ3B',
        description: `In „Paella scheißen“ begibt sich Kevin mit seinem chaotischen Freund Paul auf einen Urlaub, der alles andere als erholsam wird. Vom ersten Tag an geht alles schief: verpasste Züge, verlorenes Gepäck und ein schicksalhafter Teller Paella, der Kevins Magen in einen regelrechten Kriegsschauplatz verwandelt.`,
        title: 'Paella scheissen: Urlaub mit Katastrophengarantie (Arschhaare Rauchen 3)',
        language: 'German'
    },


]