export const servicesData = [
    {
        img: 'https://dialogmakers-international.com/img/webdevelpment.jpg',
        serviceName: 'Website Development',
        icon: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" stroke-width="2.5" stroke="#2D3949" fill="none" class="duration-300 transform transition-all" style={{ width: '48px', height: '48px' }}><path d="M39.93 55.72a24.86 24.86 0 1116.93-23.57 37.24 37.24 0 01-.73 6M37.86 51.1A47 47 0 0132 56.7"></path><path d="M32 7a34.14 34.14 0 0111.57 23 34.07 34.07 0 01.09 4.85M32 7a34.09 34.09 0 00-11.69 25.46c0 16.2 7.28 21 11.66 24.24M10.37 19.9h43.38M32 6.99V56.7M11.05 45.48h25.99M7.14 32.46l49.72-.61M53.57 57L58 52.56l-8-8 4.55-2.91a.38.38 0 00-.12-.7l-15.29-3.58a.39.39 0 00-.46.46L42 53.41a.39.39 0 00.71.13L45.57 49z"></path></svg>,
        info: 'Website development involves designing and coding websites to create functional and visually appealing online experiences.'
    },
    {
        img: 'https://dialogmakers-international.com/img/mobileapp.jpg',
        serviceName: 'App Development',
        icon: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" stroke-width="2" stroke="#2D3949" fill="none" class="duration-300 transform transition-all" style={{ width: '48px', height: '48px' }}><path d="M41.24 40.7v14.17a3.06 3.06 0 01-3.06 3.06H17a3.06 3.06 0 01-3.07-3.06V9.13A3.06 3.06 0 0117 6.07h21.18a3.06 3.06 0 013.06 3.06l-.11 9.34"></path><path stroke-linecap="round" d="M22.58 10.34h6.08M26.05 53.47h3.04M32.44 10.34h.13"></path><path d="M13.9 49.36h27.34M13.9 14.75h27.34M41.13 18.47L32.67 22a.46.46 0 00-.28.43v7.51h0a10.54 10.54 0 005.14 9l3.76 2.23L45 39a10.55 10.55 0 005.1-9v-8a.46.46 0 00-.3-.43l-8.34-3.09a.42.42 0 00-.33-.01z"></path><path d="M36.99 30.24l3.08 2.32 5.43-7.62"></path></svg>,
        info: 'App development involves designing and coding applications to create functional and engaging user experiences on mobile or desktop platforms.'
    },
    {
        img: 'https://dialogmakers-international.com/img/ads.jpg',
        serviceName: 'Project Marketing',
        icon: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" stroke-width="2.5" stroke="#2D3949" fill="none" class="duration-300 transform transition-all" style={{ width: '48px', height: '48px' }}><rect x="8.33" y="10.82" width="47.34" height="34.38" rx="2.02"></rect><path d="M8.33 37.66h47.34M23.05 53.18a6.67 6.67 0 003.55-8M41.84 53.18a6.66 6.66 0 01-3.55-8"></path><path stroke-linecap="round" d="M17.5 53.18h29M33.49 17.66l-8.54 9.14M39.03 23.84l-5.62 6.01"></path></svg>,
        info: 'Digital marketing involves promoting products or services online through various strategies like SEO, social media, email campaigns, and content marketing.'
    },
    {
        img: 'https://dialogmakers-international.com/img/email.png',
        serviceName: 'Email Marketing',
        icon: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" stroke-width="2.5" stroke="#2D3949" fill="none" class="duration-300 transform transition-all" style={{ width: '48px'}}><rect x="7.1" y="9.65" width="49.8" height="44.71" rx="2.5"></rect><path d="M7.88 9.65l25.16 21.97 23.11-21.25"></path></svg>,
        info: 'Digital marketing involves promoting products or services online through various strategies like SEO, social media, email campaigns, and content marketing.'
    },
    {
        img: 'https://dialogmakers-international.com/img/service-3.jpg',
        serviceName: 'SEO Optimization',
        icon: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" stroke-width="2.5" stroke="#2D3949" fill="none" class="duration-300 transform transition-all" style={{ width: '48px', height: '48px' }}><path d="M33.85 55.28H12.44V19.61L24.39 6.75h25.66v31.67"></path><path d="M24.41 6.75l-.02 12.86H12.44M17.59 46.68H29.6M17.59 40.15H31.6M17.59 34.05H39.6M17.59 27.95H44.6"></path><circle cx="41.76" cy="47.06" r="7.66"></circle><path d="M46.96 52.69l5.77 6.26"></path></svg>,
        info: `SEO optimization involves enhancing a website's content and structure to improve its visibility and ranking on search engines.`
    },
    {
        img: 'https://dialogmakers-international.com/img/support.jpg',
        serviceName: 'Customer Support',
        icon: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" stroke-width="2.5" stroke="#2D3949" fill="none" class="duration-300 transform transition-all" style={{ width: '48px', height: '48px' }}><path d="M12.91 31.8v-5.7a19.09 19.09 0 0138.18 0v5.7" stroke-linecap="round"></path><path d="M12.06 31.8h4.7v13.38h0-4.7a3 3 0 01-3-3V34.8a3 3 0 013-3zM51.94 45.18h-4.7 0V31.8h4.7a3 3 0 013 3v7.38a3 3 0 01-3 3zM51.7 45.56v5a4 4 0 01-4 4H36.56" stroke-linecap="round"></path><rect x="28.45" y="51.92" width="8.1" height="5.07" rx="2" stroke-linecap="round"></rect></svg>,
        info: `Customer support involves assisting and resolving customer issues and inquiries to ensure satisfaction and build loyalty.`
    },

]