import { LuMapPin, LuPhoneCall } from "react-icons/lu";
import Heading from "../../components/Heading";
import { BiEnvelope } from "react-icons/bi";
import Button from "../../components/Button";
import useDevice from "../../useDevice";
import Wrapper from "../../components/Wrapper";

const ContactUs = () => {
  const { isBigMobile, isMedLaptop } = useDevice();
  return (
    <Wrapper
      className={`contact-bg relative px-4 py-28 ${
        isBigMobile ? "pl-16 pr-16" : ""
      } overflow-hidden`}
    >
      <div className="opacity-90">
        <div className="absolute top-0 left-0 right-0 bottom-0 back-color">
          <span className="invisible">DialogMakers</span>
        </div>
      </div>
      <div className="grid lg:grid-cols-2 grid-cols-1 opacity-100">
        <div className="z-[50] ">
          <Heading title="CONNECT WITH US" heading="LET'S WORK TOGETHER" color='white'/>
          {/* <div className="flex items-center gap-6 mt-12">
            <div className="back-color-2 text-color text-3xl p-6">
              <LuPhoneCall />
            </div>
            <div className="text-gray-900">
              <div className="text-lg tracking-wider font-medium">
                HAVE QUESTION?
              </div>
              <div className="text-font mt-1 text-xl">Free +23 (000)-9850</div>
            </div>
          </div> */}
          <div className="flex items-center gap-6 py-6">
            <div className="back-color-2 text-color text-3xl p-6 flex justify-center">
              <BiEnvelope color="white"/>
            </div>
            <div className="text-gray-900">
              <div className="text-lg tracking-wider font-medium text-white">
                SEND EMAIL
              </div>
              <div className="text-font mt-1 text-xs break-all text-white">
                support@dialogmakers-international.com
              </div>
            </div>
          </div>
          <div className="flex items-center gap-6">
            <div className="back-color-2 text-color text-3xl p-6">
              <LuMapPin color="white"/>
            </div>
            <div className="text-gray-900">
              <div className="text-lg tracking-wider font-medium text-white">
                ADDRESS
              </div>
              <div className="text-font mt-1 text-xs text-white">
                2F, No. 2-1, Lane 23, Wenhua Street, Pingzhen District, Taoyuan City, 324 Taiwan
              </div>
            </div>
          </div>
        </div>
        <div className="z-[50] bg-white md:w-[80%] lg:-mt-28 mt-10 md:p-12 p-6">
          <div>
            <div className="py-2.5">
              <input
                type="text"
                name="name"
                id="name"
                className="bg-gray-100 h-16 w-full p-6 text-sm text-color-2"
                placeholder="Your name"
              />
            </div>
            <div className="py-2.5">
              <input
                type="text"
                name="name"
                id="name"
                className="bg-gray-100 h-16 w-full p-6 text-sm text-color-2"
                placeholder="Email address"
              />
            </div>
            <div className="py-2.5">
              <textarea
                className="bg-gray-100 h-40 w-full p-6 text-sm text-color-2"
                placeholder="Your message"
              ></textarea>
            </div>
            <Button title="Send Message" divClass="mt-6" />
          </div>
          {/* <div className="max-w-[50px]">
            <div
              className={`md:absolute bottom-0  hidden md:block ${
                isMedLaptop ? "-right-40" : "xl:right-16 md:right-0 lg:-right-44"
              }`}
            >
              <img src={contactBg} alt="dialogmakers-bg" />
            </div>
          </div> */}
        </div>
      </div>
    </Wrapper>
  );
};

export default ContactUs;
