import React from "react";
import NavBar from "../layouts/NavBar";
import Saturate from "./Saturate";

const PageInto = ({ pageName }) => {
    return (
        <div className="flex relative w-full md:h-[400px] lg:h-[300] phone:h-[200px] overflow-hidden">
            <div className="cursor-tail"></div>
            <div className="bg-[rgba(0,0,0,0.9)] absolute top-0 left-0 bottom-0 right-0">
                <div className="landing opacity-5"></div>
            </div>
            <div className="flex z-10 text-gray-200 w-full absolute top-0">
                <NavBar />
            </div>
            <div className="h-full absolute top-0 left-0 right-0 bottom-0">
                <Items pageName={pageName} />
            </div>
        </div>
    );
};


export const Items = ({ pageName }) => {
    const pagePath = window.location.pathname.split('/')[1];
    const pageintroImg =
        pagePath == 'services' ? 'https://images.pexels.com/photos/3082341/pexels-photo-3082341.jpeg?auto=compress&cs=tinysrgb&w=800' :
            pagePath == 'team' ? 'https://images.pexels.com/photos/3183150/pexels-photo-3183150.jpeg?auto=compress&cs=tinysrgb&w=800' :
                pagePath == 'pricing' ? 'https://images.pexels.com/photos/8970687/pexels-photo-8970687.jpeg?auto=compress&cs=tinysrgb&w=800' :
                    pagePath == 'portfolio' ? 'https://images.pexels.com/photos/39284/macbook-apple-imac-computer-39284.jpeg?auto=compress&cs=tinysrgb&w=800' :
                        pagePath == 'contact' ? 'https://images.pexels.com/photos/193003/pexels-photo-193003.jpeg?auto=compress&cs=tinysrgb&w=800' :
                            pagePath == 'about-us' ? 'https://images.pexels.com/photos/1003611/pexels-photo-1003611.jpeg?auto=compress&cs=tinysrgb&w=800' :
                                ''
    return (
        <>
            <div className="absolute right-0 top-0">
                <div className=" clip-path-custom-reversed relative">
                    <img
                        src={pageintroImg}
                        alt="dialogmakers-image"
                        className="h-[400px] w-full opacity-50"
                    />
                    <Saturate />
                </div>
            </div>
            <div className="absolute bottom-[10%] ml-[8%]">
                <div className="uppercase ">
                    <h1 className="text-h3 text-white sm:text-h2 md:text-h1 font-medium w-[50%] whitespace-nowrap mt-44">
                        {pageName}
                    </h1>
                </div>
            </div>
        </>
    );
};

export default PageInto;
