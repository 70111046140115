import PageInto from "../components/PageIntro";
import { MdOutlineMiscellaneousServices } from "react-icons/md";
import { servicesData } from "../static/services";
import { useInView } from "framer-motion";
import { useRef } from "react";




export default function Services() {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  return (
    <>
      <PageInto pageName={"Services"} />
      <span className="flex justify-center mt-8">
        <MdOutlineMiscellaneousServices size={56} color="#2D3949" />
      </span>
      <span className="text-black50 my-4 flex justify-center">
        Our Digital Services
      </span>
      <h2 className="font-bold text-black md:text-h3 phone:text-h5 text-center max-w-[900px] mx-auto px-2 mt-8 uppercase">
        Our Comprehensive Solutions for Every Digital Need
      </h2>
      <div className="flex flex-wrap justify-center my-16 mx-auto max-w-[1280px]" ref={ref}>
        {servicesData.map((val, index) => (
          <div
            style={{
              transform: isInView ? "none" : `translateY(100px)`,
              opacity: isInView ? 1 : 0,
              transition: "all 0.5s cubic-bezier(0.17, 0.55, 0.55, 1) 0.3s",
              transitionDelay: `${index / 2}s`
            }}
            className="flex flex-col border w-[336px] overflow-hidden relative m-4 transition-all duration-500 cursor-pointer bg-white hover:shadow-lg">
            <img className="w-full h-[200px]" src={val.img} alt="" />
            <div className="flex flex-col border-b m-6 py-5">
              <div className="flex items-center relative">
                <h2 className="text-black text-xl font-bold">
                  {val.serviceName}
                </h2>
                <span className="absolute right-0">{val.icon}</span>
              </div>
            </div>
            <span className="m-6 mt-2 text-black50 text-sm">{val.info}</span>
          </div>
        ))}
      </div>
    </>
  );
}
