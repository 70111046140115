import { useMediaQuery } from "react-responsive";

const useDevice = () => {
  const isBigMobile = useMediaQuery({ minWidth: 500, maxWidth: 767 });

  const isBigTablet = useMediaQuery({ minWidth: 990, maxWidth: 1023 });

  const isSmallLaptop = useMediaQuery({ minWidth: 1024, maxWidth: 1299 });

  const isMedLaptop = useMediaQuery({ minWidth: 1280, maxWidth: 1600 });

  const isMediumLaptop = useMediaQuery({ minWidth: 1300, maxWidth: 1600 });

  const isBigLaptop = useMediaQuery({ minWidth: 1300, maxWidth: 1700 });

  return {
    isBigMobile,
    isBigTablet,
    isSmallLaptop,
    isBigLaptop,
    isMediumLaptop,
    isMedLaptop
  };
};

export default useDevice;
