import logo from "../assets/logo2.png";

const Heading = ({ title, heading, w, className, logoImg, color }) => {
  return (
    <div className={className}>
      <div>
        {/* <div className={logoImg === "middle" ? "flex justify-center" : ""}>
          <img src={logo} alt="logo" width={100} />
        </div> */}
        <h4 className={`text-${color} text-base mt-4 text-gray-800  font-light uppercase py-2 tracking-wider`}>
          {title}
        </h4>
        <h2 className={`text-${color} md:text-4xl text-gray-800 text-3xl font-medium leading-tight uppercase ${w}`}>
          {heading}
        </h2>
      </div>
    </div>
  );
};

export default Heading;
