import { NavLink } from "react-router-dom";

export default function MobileMenu(props) {
  const linkStyle = "text-black my-4";
  return (
    <>
      <div className="flex flex-col fixed top-0 left-0 h-full w-full">
        <div
          onClick={() => props.menu(false)}
          className="flex bg-[#000000c7] w-full h-full absolute left-0 top-0"
        ></div>
        <div className="sm:w-[320px] phone:w-[250px] bg-white z-10 h-full">
          <h2 className="m-10 text-black font-bold text-h4">Menu</h2>
          <div className="flex flex-col mx-10">
            <NavLink to={"/"} className={linkStyle}>
              Home
            </NavLink>
            <NavLink to={"/about-us"} className={linkStyle}>
              About
            </NavLink>
            <NavLink to={"/team"} className={linkStyle}>
              Team
            </NavLink>
            <NavLink to={"/services"} className={linkStyle}>
              Services
            </NavLink>
            <NavLink to={"/pricing"} className={linkStyle}>
              Pricing
            </NavLink>
            <NavLink to={"/portfolio"} className={linkStyle}>
              Portfolio
            </NavLink>
            <NavLink to={"/contact"} className={linkStyle}>
              Contact
            </NavLink>
            <span className="flex text-xs text-black50 w-full absolute bottom-4">
              DailogMakers-International
            </span>
          </div>
        </div>
      </div>
    </>
  );
}
