import { FaRocket } from "react-icons/fa6";
import { SiMaterialdesignicons } from "react-icons/si";
import { HiOutlineLightBulb } from "react-icons/hi";
import { FaComputer } from "react-icons/fa6";
import { FaPlateWheat } from "react-icons/fa6";
import portfolio1 from "./assets/portfolio1.jpg";
import portfolio2 from "./assets/portfolio2.jpg";

export const navOptions = [
  {
    option: "Home",
    link: "/",
  },
  {
    option: "Services",
    link: "/services",
  },
  {
    option: "Team",
    link: "/team",
  },
  {
    option: "Pricing",
    link: "/pricing",
  },
  {
    option: "Portfolio",
    link: "/portfolio",
  },
  {
    option: "Contact",
    link: "/contact",
  },
  {
    option: "About",
    link: "/about-us",
  },
];

export const services = [
  {
    icon: <SiMaterialdesignicons className="service-icon"/>,
    title: "UI / UX CREATIVE DESIGN",
  },
  {
    icon: <FaRocket className="service-icon"/>,
    title: "VISUAL GRAPHIC DESIGN",
  },
  {
    icon: <HiOutlineLightBulb className="service-icon"/>,
    title: "STRATEGY & MARKETING",
  },
  {
    icon: <FaPlateWheat className="service-icon"/>,
    title: "CONTENT WRITTING SKILLS",
  },
  {
    icon: <FaComputer className="service-icon"/>,
    title: "WEB & APP DEVELOPMENT",
  },
];

export const portfolio = [
  {
    image: portfolio1,
  },
  {
    image: portfolio2,
  },
  {
    image: portfolio1,
  },
  {
    image: portfolio2,
  },
];

