import logo from "../../assets/logo-light.png";
import Wrapper from "../../components/Wrapper";
import ServiceItems from "../../components/ServiceItems";
import Heading from "../../components/Heading";
import { LuServerCog } from "react-icons/lu";


const Services = () => {
  return (
    <Wrapper className="text-white services-bg">
      <LuServerCog color="white" size={40} />
      <div className="md:flex text-color-2 justify-between text-white">
        <Heading
          title="Our Services"
          heading="WHAT WE'RE OFFERING TO OUR CUSTOMERS"
          w="md:w-[70%]"
          className="pb-6 md:pb-0"
          color='white'
        />
        <div className="text-md text-font md:w-[50%] border-t-4 border-gray-900 pt-5">
          <p className="leading-loose break-all text-white">
            Dialogmakers is a home for creative heads around the world. With our experienced customer support professionals, we think entrepreneurially, look into the future with our customers, and fultill the goal and vision together.
          </p>
        </div>
      </div>
      <div>
        <ServiceItems />
      </div>
    </Wrapper>
  );
};

export default Services;
