import React from "react";
import PageInto from "../components/PageIntro";
import About from "../components/About";


const AboutPage = () => {
  return (
    <>
      <PageInto pageName={'About Us'}/>
      <About />
    </>
  );
};

export default AboutPage;
