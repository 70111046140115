import { useRef } from "react";
import { services } from "../static-data";
import useDevice from "../useDevice";
import { useInView } from "framer-motion";


const ServiceItems = () => {
  const { isBigMobile } = useDevice();
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  return (
    <div
      ref={ref}
      style={{
        transform: isInView ? "none" : "translateY(100px)",
        opacity: isInView ? 1 : 0,
        transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s"
      }}
      className={`grid lg:grid-cols-5 ${isBigMobile ? "grid-cols-2" : ""
        } grid-cols-1 mt-12 gap-4 md:grid-cols-3`}
    >
      {services.map((service, index) => {
        return (
          <ServiceItem
            key={index}
            icon={service.icon}
            title={service.title} />
        );
      })}
    </div>
  );
};

export const ServiceItem = ({ icon, title }) => {
  return (
    <div
      className="service-box bg-black text-center py-12 break-words rounded-lg"
    >
      <div className="text-gray-600 text-6xl flex justify-center">
        {icon}
      </div>
      <div className="text-gray-200 pt-6 text-xl uppercase px-6 text-md">
        <span className="service-title">
          {title}
        </span>
      </div>
    </div>
  );
};

export default ServiceItems;
