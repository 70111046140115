export default function About() {
    return (
        <div className="mx-8">
            <div className="phone:flex flex-col lg:grid grid-cols-2 xl:max-w-[1300px] lg:max-w-[1000px] my-16 mx-auto gap-2">
                <div className="flex border-2 border-primary p-2 relative">
                    <img
                        className="flex w-full"
                        src={'https://dialogmakers-international.com/img/img.jpg'}
                        alt="header"
                    />
                </div>
                <div className="flex flex-col lg:mx-8">
                    <h4 className="text-lg text-black75 mt-4 font-normal tracking-widest uppercase">
                        about us
                    </h4>
                    <div className="mb-8 md:text-h2 font-ExtraBold phone:text-h5 font-medium uppercase">
                        <span className="text-primary">#1 Digital Solution With</span>
                        <span className="text-black"> 4 Years Of Experience</span>
                    </div>
                    <p className="text-base text-black75 font-medium">
                        In 2020, a young start-up company from New Taipei, Taiwan arrives at a new way to feel online communication in communities. With the creation of our own AI personality <strong>MAZU, </strong>  we embrace a completely new way to enjoy community features – without losing the sense of reality and authenticity to this world that we love so much as if we could have our first date in a bar or café, practice for the test for the driver licence at home, study for the next exam at the university, or sell or buy stuff around the world at the spur of the moment.
                    </p>
                    <p className="text-base text-black75 font-medium my-2">
                        <strong>Dialogmakers</strong> is a home for creative heads around the world. With our experienced customer support professionals, we think entrepreneurially, look into the future with our customers, and fultill the goal and vision together.
                    </p>
                </div>
            </div>

            <div className="flex phone:flex-col lg:flex-row  w-full justify-center items-center my-16 bg-primary xl:max-w-[1300px] lg:max-w-[1000px] p-8 mx-auto rounded-md">
                <div className="flex phone:flex-col sm:flex-row items-center mx-8 items-center phone:my-4 lg:my-0 phone:w-[400px]">
                    <img
                        className="w-[100px] rounded-full phone:my-2 sm:my-0"
                        src="https://dialogmakers-international.com/img/JOE1.jpg"
                        alt="" />
                    <div className="mx-4 phone:text-center sm:text-left">
                        <h3 className="font-bold text-2xl text-white">Chao Ching Hsu</h3>
                        <span className="text-white">Director & Founder</span>
                    </div>
                </div>
                <div className="flex mx-8 phone:flex-col sm:flex-row items-center phone:my-4 lg:my-0 phone:w-[400px]">
                    <img
                        className="w-[100px] rounded-full phone:my-2 sm:my-0"
                        src="https://dialogmakers-international.com/img/ericceo.jpg"
                        alt="" />
                    <div className="mx-4 phone:text-center sm:text-left">
                        <h3 className="font-bold text-2xl text-white">Eric Rönnau</h3>
                        <span className="text-white">CEO & Founder</span>
                    </div>
                </div>
            </div>
        </div>
    )
}