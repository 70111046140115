import HomePage from "./pages/HomePage";
import "./App.css";
import { useEffect, useRef } from "react";
// import Contact from "./pages/Contact";
// import Pricing from "./pages/Pricing";
// import Team from "./pages/Team";

const App = () => {
  const cursorRef = useRef();
  useEffect(() => {
    const handleMouseMove = (e) => {
      if (cursorRef.current) {
        cursorRef.current.style.transform = `translate(${e.clientX - 10}px, ${
          e.clientY - 10
        }px)`;
      }
    };
    document.addEventListener("mousemove", handleMouseMove);
    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);
  
  return <HomePage cursorRef={cursorRef} />;
};

export default App;
